Object.keys(Alkami.FlashBanner).forEach((key) => {
   const oldKey = key.replace('show', 'showFlash').replace('hide', 'hideFlash');

   window[oldKey] = function() {
      console.warn(`${oldKey} is deprecated. Please use Alkami.FlashBanner.${key} instead.`);
      return Alkami.FlashBanner[key].apply(null, arguments);
   };
});

declare function showFlashBulletin(msg: string): JQuery;
declare function showFlashCaution(msg: string): JQuery;
declare function showFlashError(msg: string): JQuery;
declare function showFlashMessage(msg: string): JQuery;
declare function showFlashInfo(msg: string): JQuery;
declare function showFlashSuccess(msg: string): JQuery;
declare function showFlashWarning(msg: string): JQuery;
